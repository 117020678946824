import router from "../../../../router";

export default {
    name: "WarrantSettlementCalculator",
    created() {
        // this.$$.navbarAnimation()
        this.$nextTick(function () {
            this.ariseCombobox('#comboboxWarrants')
            // 获取ric
            this.getRiclist()
        });
    },
    mounted() {
        this.$nextTick(function () {

        })
    },
    data() {
        return {
            ricData: [],
            ricNmae: '',
            close: [],
            curr: '',
            averageClosing: '',
            Quantity: 0,
            ExercisePrice: '',
            ExerciseRatio: '',
            CashSettlementAmount: '',
            type: ''
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        /*获取ric list*/
        getRiclist: function () {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetRICList",
                data: { token: "webkey" },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        this.ricData = data.ric
                        this.ricNmae = this.ricData[0].ric
                        this.GetMarketData(this.ricData[0].ric)
                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 接收Marke数据
        GetMarketData: function (ric) {
            let _this = this
            $.ajax({
                type: "POST",
                url: this.$$.mibb + "GetMarketData",
                dataType: "json",
                data: { token: "webkey", type: "warrantdata", ric: ric },
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        $(".ui-widget").find("input").val(data[ric].dsply_nmll);
                        $.each(data[ric], function (index, value) {
                            if (
                                index.indexOf(index.replace(/underlying_close/gi, "")) > 0 &&
                                index.indexOf(index.replace(/[^0-9]/gi, "")) > 0
                            ) {
                                _this.close[index.replace(/[^0-9]/gi, "") - 1] = parseFloat(value);

                            }
                        });
                        this.curr = data[ric].underlying_curr
                        this.averageClosing = this.$$.formatNumber(this.sumAvg(this.close), 3, 0)
                        this.Quantity = 0
                        this.ExercisePrice = this.$$.formatNumber(data[ric].exercise_price, 3, 0)
                        this.ExerciseRatio = this.$$.formatNumber(data[ric].conv_ratio, 5, 0)
                        // if (Number(data[ric].exercise_price) - Number(this.sumAvg(this.close)) > 0) {
                        //     this.CashSettlementAmount = (((Number(this.sumAvg(this.close)) - Number(data[ric].exercise_price)) / Number(data[ric].conv_ratio)) * this.Quantity)
                        // } else {
                        //     this.CashSettlementAmount = 0
                        // }
                        this.CashSettlementAmount = (((Number(this.sumAvg(this.close)) - Number(data[ric].exercise_price)) / Number(data[ric].conv_ratio)) * this.Quantity)
                        this.CashSettlementAmount = this.$$.formatNumber(this.CashSettlementAmount, 2, 0)
                        this.type = data[ric].type
                        if (data[ric].type == 'CALL') {
                            if (Number(data[ric].exercise_price) > this.CashSettlementAmount) {
                                this.CashSettlementAmount = 0
                            }
                        } else {
                            if (Number(data[ric].exercise_price) < this.CashSettlementAmount) {
                                this.CashSettlementAmount = 0
                            }
                        }


                    }
                },
                error: function (jqXHR) {

                }
            });
        },
        //求平均数
        sumAvg: function (arr) {
            var len = arr.length;
            if (len == 0) {
                return 0;
            } else if (len == 1) {
                return arr[0];
            } else {
                return eval(arr.join("+")) / arr.length;
            }
        },
        Reset: function () {
            this.GetMarketData(this.ricNmae);
        },
        // Underling Target Price input方法
        averageClosingInput: function (e) {

            this.averageClosing = e.target.value
            // console.log(this.averageClosing);
            // if (Number(this.ExercisePrice) - Number(this.averageClosing) > 0) {
            //     this.CashSettlementAmount = ((Number(this.averageClosing) - Number(this.ExercisePrice)) / Number(this.ExerciseRatio)) * this.Quantity
            //     console.log(this.CashSettlementAmount)
            // } else {
            //     this.CashSettlementAmount = 0
            // }
            this.CashSettlementAmount = ((Number(this.averageClosing) - Number(this.ExercisePrice)) / Number(this.ExerciseRatio)) * this.Quantity
            this.CashSettlementAmount = this.$$.formatNumber(this.CashSettlementAmount, 2, 0)
            if (this.type == 'CALL') {
                if (Number(this.ExercisePrice) > this.CashSettlementAmount) {
                    this.CashSettlementAmount = 0
                }
            } else {
                if (Number(this.ExercisePrice) < this.CashSettlementAmount) {
                    this.CashSettlementAmount = 0
                }
            }
            this.CashSettlementAmount = Math.abs(this.CashSettlementAmount)

        },
        QuantityInput: function (e) {
            this.Quantity = e.target.value
            // if (Number(this.ExercisePrice) - Number(this.averageClosing) > 0) {
            //     this.CashSettlementAmount = ((Number(this.averageClosing) - Number(this.ExercisePrice)) / Number(this.ExerciseRatio)) * this.Quantity
            // } else {
            //     this.CashSettlementAmount = 0
            // }
            
            this.CashSettlementAmount = ((Number(this.averageClosing) - Number(this.ExercisePrice)) / Number(this.ExerciseRatio)) * this.Quantity
            this.CashSettlementAmount = this.$$.formatNumber(this.CashSettlementAmount, 2, 0)
            if (this.type == 'CALL') {
                if (Number(this.ExercisePrice) > this.CashSettlementAmount) {
                    this.CashSettlementAmount = 0
                }
            } else {
                if (Number(this.ExercisePrice) < this.CashSettlementAmount) {
                    this.CashSettlementAmount = 0
                }
            }
            
            this.CashSettlementAmount = Math.abs(this.CashSettlementAmount)
        },
        // 出现combobox
        ariseCombobox: function (id) {
            let _this = this
            this.$$.jqcombobox(id)
            $(id).combobox({
                select: function (event, ui) {
                    this.ricNmae = this.value
                    _this.GetMarketData(this.value)
                }
            });
            $(id).parent().find('input').attr('placeholder', this.$t('WCr.SW'))
        },
    }
};